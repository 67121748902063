<template>
  <brand-header :image="urlImage" :altImage="altImagen"/>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <a href="/" class="header-content__container-logo">
          <img class="header-content__img" src="../assets/images/logo.svg" alt="wagerStore" />
        </a>
      </div>
      <div class="header-content__cta">
        <div class="header-buttons">
          <form class="form-login form-desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" placeholder="User" name="account" id="account">
            <input type="password" placeholder="Password" name="password" id="password">
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
            <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal">BETSLIP</a>
          </form>
          <form class="form-login form-mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <div class="form-mobile__container">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
            <input type="text" placeholder="User" name="account" id="account">
            <input type="password" placeholder="Password" name="password" id="password">
            </div>
            <div class="form__btn">
            <button class="btn-login secondary-button secondary-button__betslip" type="submit">LOGIN</button>
            <a href="#" class="btn-bet secondary-button secondary-button__betslip" @click="openModal">BETSLIP</a>
          </div>
          </form>

        </div>
        
      </div>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

  <script>
  
  import ModalComponent from '@/components/modal.vue'
  export default {
    name: "HeaderComponent",
    components: {
      ModalComponent,
    },
    setup() {
      const DGS_SITEID = 1068;
      const backendUrl = "kingbet.ag";
      return {
        DGS_SITEID,
        backendUrl,
      }
    },
    data() {
      return {
        siteUrl: "kingbet.ag"
      }
    },
    methods: {
      openModal() {
        const screenWidth = window.innerWidth;
        const DgsBackendUrl = screenWidth <= 764 ? `//mobile.${this.siteUrl}/DefaultLogin.aspx` : `//wager.${this.siteUrl}/DefaultLogin.aspx`;
    
        this.$refs.modal.openModal(DgsBackendUrl);
      }
    }
  };
</script>