<template>
  <footer class="footer">
    <div class="footer-content">
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn1" :key="index">
          <router-link @click="scrollToTop" :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn2" :key="index">
          <router-link @click="scrollToTop" :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <ul class="footer-content__list">
        <li class="primary-button primary-button__classic" @click="isActive = !isActive">
          <a target="_blank" href="https://agents.kingbet.ag/">agent classic</a>
        </li>
        <li class="primary-button primary-button__classic" @click="isActive = !isActive">
          <a target="_blank" href="https://adm.kingbet.ag ">agent new</a>
        </li>
      </ul>
    </div>
    <div class="footer-content__rights">
      <p>Kingbet © All Rights Reserved</p>
    </div>
    
  </footer>
</template>


<script>


  export default {
    methods: {
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    name: "FooterComponent",
    components: {
    },
    setup() {
      return {
        routesColumn1: [
          {
            name       :  'sportsbook',
            route      :  'sportsbook'
          },
          {
            name       :  'casino',
            route      :  'casino'
          },
          {
            name       :  'racebook',
            route      :  'racebook'
          }
        ],
        routesColumn2: [
          {
            name       :  'live betting',
            route      :  'live-betting'
          },
          {
            name       :  'sports rules',
            route      :  'sports-rules'
          },
          {
            name       :  'house rules',
            route      :  'house-rules'
          },
          {
            route      :  'same-game-parlay-rules',
            name        : 'same game parlay rules'
          }
        ]
      }
    },
  };
</script>
